<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-organization-list' }">Organizations</router-link>
                    <template v-if="organization">
                    &gt;
                    <router-link :to="{ name: 'organization-dashboard', params: { organizationId: this.$route.params.organizationId } }">{{ organization.name }}</router-link>
                    </template>
                    &gt;
                    <router-link :to="{ name: 'organization-search-client', params: { organizationId: this.$route.params.organizationId } }">Clients</router-link>
                </p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10 mt-2" v-if="client">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <template v-if="displayToken">
                        <v-alert type="info" border="top" colored-border>
                            <v-row align="top">
                                <v-col class="grow">
                                    <p>A new API token was created:</p>
                                    <v-textarea v-model="displayToken" ref="displayTokenRef" readonly outlined @focus="selectTokenForClipboard">
                                        <template #append-outer>
                                            <v-btn icon @click="copyTokenToClipboard">
                                                <font-awesome-icon :icon="['far', 'copy']"></font-awesome-icon>
                                            </v-btn>
                                        </template>
                                    </v-textarea>
                                    <p>Copy it to a secure location before closing this message. We cannot display the token again after this message is closed.</p>
                                    <!-- <v-btn @click="displayToken = null">Close</v-btn> -->
                                </v-col>
                                <v-col class="shrink">
                                    <v-btn icon @click="displayToken = null" color="black">
                                        <font-awesome-icon :icon="['fas', 'times']"></font-awesome-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-alert>
                    </template>

                    <p class="text-caption text-end grey--text text--darken-2 mb-0">Client {{ client.id }}</p>
                    <v-card class="pa-0 mt-0">
                        <v-toolbar dense flat color="green darken-2" dark>
                            <!-- TODO: the draft/published feature here from unicorn springs could be repurposed to an active/inactive, or revoke access feature, so user can revoke access for a client without deleting the record (so we can still know the client id in logs etc.) -->
                            <v-toolbar-title>
                                Client
                                <!-- <span v-if="client.is_draft_pending && !client.published">(draft)</span>
                                <span v-if="client.is_draft_pending && client.published">(published)</span> -->
                                <!-- <v-chip v-if="client.is_active" label small class="px-1 ml-2 green darken-2 white--text">Active</v-chip>
                                <v-chip v-if="!client.is_active" label small class="px-1 ml-2 green darken-2 black--text">Inactive</v-chip> -->
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <!-- <v-btn text small class="px-1 ml-2 green darken-2 white--text" @click="publishClient" v-if="client.is_draft_pending && client.is_active">Update</v-btn>
                            <v-btn text small class="px-1 ml-2 green darken-2 white--text" @click="publishClient" v-if="client.is_draft_pending && !client.is_active">Publish</v-btn>
                            <v-btn icon color="white" @click="publishClient" v-if="client.is_draft_pending">
                                <font-awesome-icon :icon="['fas', 'cloud-upload-alt']" fixed-width></font-awesome-icon>
                            </v-btn> -->
                        </v-toolbar>
                        <v-card-text>
                            <p class="text-overline mb-0 mt-4">Label</p>
                            <p class="mb-0 pb-0 mt-0">
                                <EditableText :value="client.label" @input="saveClientLabel" dense/>
                            </p>

                            <p class="text-overline mb-0 mt-8">Security</p>
                            <p>Allowed origin protocols</p>
                            <p class="grey--text text--darken-1 text-caption">By default, clients are required to provide <var>https</var> URLs for redirecting users after a profile request. However, when developing a client application it may be extremely inconvenient to set up <var>https</var>. You can choose to allow <var>http</var> in a development environment. We strongly recommend to <strong>not</strong> enable <var>http</var> in production.</p>
                            <p class="mb-0 pb-0 mt-0">
                                <EditableTextSelectMultiple :value="permitOriginProtocolList" :items="clientPermitOriginProtocolChoices" @input="saveClientPermitOriginProtocol" dense/>
                            </p>

                            <!-- TODO: enter flat rate shipping (when using USPS) or set up custom rate shipping integration for FedEx, UPS, etc. based on package size and weight and the carrier... various services exist that help with the computation, or we can do our own query of the current rates and compute for the merchant... -->

                            <!-- <p class="text-overline mb-0 mt-8">Description</p>
                            <p class="text-caption mb-0 pb-0 mt-0">The description is PUBLIC and is shown to customers.</p>
                            <p class="mb-0 pb-0 mt-0">
                                <EditableText :value="client.description" @input="saveClientDescription" dense/>
                            </p> -->

                            <!-- TODO: move to comment component -->
                            <!-- <p class="text-overline mb-0 mt-8">Comment</p>
                            <p class="text-caption mb-0 pb-0 mt-0">The comment is PRIVATE and is NOT shown to customers.</p>
                            <p class="mb-0 pb-0 mt-0">
                                <EditableText :value="client.comment" @input="saveClientComment" dense/>
                            </p> -->
                            <!-- <p class="mb-0 pb-0">
                                <TextLink :href="viewBrandProfileURL" target="_blank">{{ viewBrandProfileURL }}</TextLink>
                            </p> -->
                            <!-- <v-divider/> -->
                            <!-- <p class="text-overline mb-0 mt-8">
                                API Tokens
                                <v-btn icon color="green darken-2" @click="createToken">
                                    <font-awesome-icon :icon="['fas', 'plus']" fixed-width></font-awesome-icon>
                                </v-btn>
                            </p> -->
                            <!-- TODO: replace this with client token list -->
                            <!-- <v-list v-if="Array.isArray(clientTokenList)">
                                <v-list-item v-for="item in clientTokenList" :key="item.id">
                                    <v-list-item-content>
                                        <EditableClientToken :value="item" :client="client" @input="saveClientToken"/>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list> -->
                            <!-- <v-card flat tile color="white">
                                <v-toolbar dense flat color="white">
                                    <v-toolbar-title></v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-btn icon color="green darken-2">
                                        <font-awesome-icon :icon="['fas', 'plus']" fixed-width></font-awesome-icon>
                                    </v-btn>
                                </v-toolbar>
                            </v-card> -->
                        </v-card-text>
                    </v-card>
                    <!-- <v-dialog v-model="addTokenDialog" max-width="600">
                    <v-card class="pa-0" max-width="600">
                        <v-toolbar short flat color="green lighten-3">
                            <v-toolbar-title >Add Token</v-toolbar-title>
                        </v-toolbar>
                        <v-form @submit.prevent="createToken" @keyup.enter.native.prevent="createToken" class="px-2">
                            <v-text-field v-model="newClientTokenLabel" label="Label" hint="A label for this client token; for example which application or server is using it" ref="newClientTokenLabelInput"></v-text-field>
                        </v-form>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation="4" class="green white--text" @click="createToken" :disabled="!isCreateTokenFormComplete">
                                <span>Create</span>
                            </v-btn>
                            <v-btn text color="grey" @click="addTokenDialog = false">
                                <span>Cancel</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                    </v-dialog> -->
                    <v-card class="pa-0 mt-6" v-if="clientTokenList">
                        <v-app-bar color="green darken-2" dark flat dense>
                            <v-app-bar-title>Client tokens ({{ clientTokenList.length }})</v-app-bar-title>
                            <v-spacer/>
                            <v-btn icon @click="createNewClientTokenDialog = true">
                                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width></font-awesome-icon>
                            </v-btn>
                        </v-app-bar>

                        <v-card-text v-if="clientTokenList.length === 0">
                            <p>A client token grants permissions to your application to manage organizations, users, orders, and more via the Unicorn Springs API.</p>
                            <!-- <v-btn style="background-color: green darken-2; color: #ffffff;" elevation="2" @click="createNewClientTokenDialog = true">
                                Add
                            </v-btn> -->
                        </v-card-text>

                        <v-list dense v-if="clientTokenList.length > 0">
                            <v-list-item v-for="(item, idx) in clientTokenList" :key="idx" @click="openEditClientTokenDialog(item)">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ item.label }} <!-- .{{ item.domain }} -->
                                        <v-chip v-if="item.value === null" label small class="px-1 ml-2 green white--text">New</v-chip>
                                        <v-chip v-if="Array.isArray(item.value) && item.value.length === 1" label small class="px-1 ml-2 grey white--text">{{ ipaddr }}</v-chip>
                                        <template v-if="Array.isArray(item.value) && item.value.length > 1">
                                            <!-- TODO: do we need to limit how many are on the line, and offer a popup or something to see the full list of ipaddr? -->
                                            <v-chip v-for="(ipaddr, idx) in item.value" :key="idx" label small class="px-1 ml-2 grey white--text">{{ ipaddr }}</v-chip>
                                        </template>
                                        <!-- <v-chip v-if="item.status === 'new'" label small class="px-1 ml-2 yellow">{{ item.status }}</v-chip>
                                        <v-chip v-if="item.status === 'pending'" label small class="px-1 ml-2 green lighten-4">{{ item.status }}</v-chip>
                                        <v-btn icon small color="green darken-2" @click="openEditClientTokenDialog(item)">
                                            <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width></font-awesome-icon>
                                        </v-btn> -->
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card>
                    <v-dialog v-model="createNewClientTokenDialog" max-width="600">
                    <v-card class="pa-0" max-width="600">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title class="green--text text--darken-2">Add Client Token</v-toolbar-title>
                        </v-toolbar>
                        <!-- <v-card-text class="px-5">
                        </v-card-text> -->
                        <v-form @submit.prevent="createClientToken" @keyup.enter.native.prevent="createClientToken" class="px-2">
                            <v-text-field v-model="newClientTokenLabel" label="Label" hint="A label for this client token; for example which application or server is using it" ref="newClientTokenLabelInput"></v-text-field>
                        </v-form>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation="4" class="green white--text" @click="createClientToken" :disabled="!isNewClientTokenFormComplete">
                                <span>Create</span>
                            </v-btn>
                            <v-btn text color="grey" @click="createNewClientTokenDialog = false">
                                <span>Cancel</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                    </v-dialog>
                    <v-dialog v-model="editClientTokenDialog" max-width="600">
                    <v-card class="pa-0" max-width="600">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title class="green--text text--darken-2">API token</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="px-5">
                            <p class="text-overline mb-0">Client</p>
                            <p class="mb-0">{{ editClientTokenLabel }}<!-- .{{ editItemDomain }} --></p>

                            <!-- <p class="text-overline mb-0 mt-4">IP Address</p>
                            <p v-if="!Array.isArray(editItemValue) || editItemValue.length === 0">Use the Dynamic DNS client software to update this record.</p>
                            <v-list v-if="Array.isArray(editItemValue) && editItemValue.length > 1">
                                <v-list-item v-for="(ipaddr, idx) in editItemValue" :key="idx">
                                    <v-list-item-title>{{ ipaddr }}</v-list-item-title>
                                </v-list-item>
                            </v-list> -->

                            <!-- <p class="text-overline mb-0">Client software</p>
                            <p class="mb-0">Link to download client</p>
                            <p class="mb-0">Link to get access token</p> -->

                            <p class="text-overline mb-0 mt-4">Other</p>
                            <p><router-link :to="{ name: 'organization-delete-client-token', params: { organizationId: this.$route.params.organizationId }, query: { id: this.editItemId } }">How to delete this API token</router-link></p>

                            <!-- <v-form @submit.prevent="editItem" @keyup.enter.native.prevent="editItem" class="px-2">
                                <v-select v-model="editItemStatus" label="Status" :items="statusChoices"></v-select>
                            </v-form> -->

                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <!-- <v-btn elevation="4" class="green white--text" @click="editItem" :disabled="!isEditItemFormComplete">
                                <span>Save</span>
                            </v-btn> -->
                            <v-btn text color="grey" @click="editClientTokenDialog = false">
                                <span>Close</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                    </v-dialog>

                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import EditableText from '@/components/EditableText.vue';
// import EditableTextSelect from '@/components/EditableTextSelect.vue';
import EditableTextSelectMultiple from '@/components/EditableTextSelectMultiple.vue';
// import EditableClientToken from '@/components/EditableClientToken.vue';
// import TextLink from '@/components/TextLink.vue';

export default {
    components: {
        // TextLink,
        EditableText,
        // EditableTextSelect,
        EditableTextSelectMultiple,
        // EditableClientToken,
    },
    data: () => ({
        organization: null,
        client: null,
        clientTokenList: null,
        status: null,
        error: null,

        dialogEditBrandProfile: false,
        editableBrandProfileAlias: null,
        submitFormTimestamp: null,

        addTokenDialog: false,

        displayToken: null,

        createNewClientTokenDialog: false,
        newItemDomain: null,
        newClientTokenLabel: null,

        editClientTokenDialog: false,
        editItemId: null,
        editItemDomain: null,
        editClientTokenLabel: null,
        editItemValue: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
            clientPermitOriginProtocolChoices: (state) => state.clientPermitOriginProtocolChoices,
            clientPublishedChoices: (state) => state.clientPublishedChoices,
        }),
        isViewReady() {
            return this.organization !== null;
        },
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        isEditBrandProfileAliasFormComplete() {
            return this.editableBrandProfileAlias;
        },
        isNewClientTokenFormComplete() {
            return typeof this.newClientTokenLabel === 'string' && this.newClientTokenLabel.trim().length > 0;
        },
        // customerClientLink() {
        //     // TODO: get default site with possible custom hostname...  and the brandprofile, if needd...
        //     return "";http://customer.etherlink-main.test/brand/libertydns/client?id=06J5B62A4Z7WRCCBET4G
        // },
        yesnoChoices() {
            return [
                { text: 'Yes', value: true },
                { text: 'No', value: false },
            ];
        },
        permitOriginProtocolList() {
            const list = this.client.permit?.origin_protocol ?? [];
            if (Array.isArray(list)) {
                return list;
            }
            return [];
        },
    },
    watch: {
        dialogEditBrandProfile(newValue) {
            if (newValue) {
                this.editableBrandProfileAlias = this.brandprofile;
                this.$nextTick(() => {
                    setTimeout(() => { this.$activateInput('editableBrandProfileInput'); }, 1);
                });
            }
        },
    },
    methods: {
        async loadOrganization() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadOrganization: true });
                const response = await this.$client.organization(this.$route.params.organizationId).currentOrganization.get();
                console.log(`organization/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.organization = response;
                } else {
                    // TODO: redirect back to organization list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load organization', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadOrganization: false });
            }
        },
        async loadClient() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadClient: true });
                const query = { id: this.$route.query.id };
                if (this.$route.query.published) {
                    query.published = this.$route.query.published; // 'true' or 'false'
                }
                const response = await this.$client.organization(this.$route.params.organizationId).client.get(query);
                console.log(`editclient.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.client = response;
                    this.loadClientTokenList();
                    // this.checkClient();
                } else {
                    // TODO: redirect back to organization list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load client', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadClient: false });
            }
        },
        // async checkClient() {
        //     try {
        //         this.error = false;
        //         this.$store.commit('loading', { checkClient: true });
        //         const query = { id: this.$route.query.id };
        //         const response = await this.$client.organization(this.$route.params.organizationId).client.check(query, { item: 'stripe' });
        //         console.log(`editclient.vue: response ${JSON.stringify(response)}`);
        //         if (response) {
        //             this.status = response;
        //         } else {
        //             this.status = null;
        //         }
        //     } catch (err) {
        //         console.error('failed to check client status', err);
        //         this.status = null;
        //         this.error = true;
        //     } finally {
        //         this.$store.commit('loading', { checkClient: false });
        //     }
        // },
        async loadClientTokenList() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadClientTokenList: true });
                const query = { client_id: this.$route.query.id };
                if (this.$route.query.published === 'true') {
                    query.published = 'true'; // this.$route.query.published; // 'true' or 'false'
                } else {
                    query.published = 'false';
                }
                const response = await this.$client.organization(this.$route.params.organizationId).clientToken.search(query);
                console.log(`editclient.vue: response ${JSON.stringify(response)}`);
                if (Array.isArray(response.list)) {
                    this.clientTokenList = response.list;
                } else {
                    this.clientTokenList = [];
                }
                // check if any of the tokens have a draft pending, and enable the publish button for that
                const isTokenDraftPending = this.clientTokenList.reduce((acc, cur) => acc || cur, false);
                if (isTokenDraftPending) {
                    this.$set(this.client, 'is_draft_pending', true);
                }
            } catch (err) {
                console.error('failed to load tokens', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadClientTokenList: false });
            }
        },
        async editBrandProfileAlias() {
            try {
                if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                    return;
                }
                this.submitFormTimestamp = Date.now();
                this.error = false;
                this.$store.commit('loading', { editBrandProfileAlias: true });
                const response = await this.$client.organization(this.$route.params.organizationId).setting.edit({ name: 'brandprofile' }, { content: this.editableBrandProfileAlias });
                console.log(`editBrandProfileAlias: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.dialogEditBrandProfile = false;
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    this.loadSetting();
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit BrandProfile' });
                }
            } catch (err) {
                console.error('failed to edit BrandProfile', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit BrandProfile' });
            } finally {
                this.$store.commit('loading', { editBrandProfileAlias: false });
            }
        },
        async saveClientAttr(name, value) {
            try {
                this.error = false;
                this.$store.commit('loading', { saveClientAttr: true });
                const response = await this.$client.organization(this.$route.params.organizationId).client.edit({ id: this.$route.query.id }, { [name]: value });
                console.log(`saveClientAttr: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.$set(this.client, name, value);
                    this.$set(this.client, 'is_draft_pending', true);
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit client' });
                }
            } catch (err) {
                console.error(`failed to edit client attr [${name}]: ${JSON.stringify(value)}`, err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit client' });
            } finally {
                this.$store.commit('loading', { saveClientAttr: false });
            }
        },
        async saveClientLabel(label) {
            this.saveClientAttr('label', label);
        },
        async saveClientPermitOriginProtocol(list) {
            const permit = this.client.permit ?? {};
            permit.origin_protocol = list;
            await this.saveClientAttr('permit', permit);
        },
        // async saveClientRequireName(value) {
        //     const checkout = this.client.checkout ?? {};
        //     checkout.require_name = value;
        //     this.saveClientAttr('checkout', checkout);
        // },
        // async saveClientRequireEmail(value) {
        //     const checkout = this.client.checkout ?? {};
        //     checkout.require_email = value;
        //     this.saveClientAttr('checkout', checkout);
        // },
        // async saveClientRequireSignup(value) {
        //     const checkout = this.client.checkout ?? {};
        //     checkout.require_signup = value;
        //     this.saveClientAttr('checkout', checkout);
        // },
        // async saveClientRequireBilling(value) {
        //     const checkout = this.client.checkout ?? {};
        //     checkout.require_billing = value;
        //     this.saveClientAttr('checkout', checkout);
        // },
        // async saveClientRequireShipping(value) {
        //     const checkout = this.client.checkout ?? {};
        //     checkout.require_shipping = value;
        //     this.saveClientAttr('checkout', checkout);
        // },
        // async saveClientDescription(description) {
        //     this.saveClientAttr('description', description);
        // },
        // TODO: redo this with new comment API and comment collection by ADDING a comment or EDITING an existing comment or DELETING an existing comment ; but these need to be done just once because comments can apply to any object; so we need a comment component that will show up on this view and user cna tap it to manage comments
        // async saveClientComment(comment) {
        //     this.saveClientAttr('comment', comment);
        // },
        /*
        async publishClient() {
            try {
                this.error = false;
                this.$store.commit('loading', { publishClient: true });
                const response = await this.$client.organization(this.$route.params.organizationId).client.publish({ id: this.$route.query.id });
                console.log(`publishClient: response ${JSON.stringify(response)}`);
                if (typeof response?.isPublished === 'boolean') {
                    this.$set(this.client, 'is_active', response.isPublished);
                    if (response.isPublished) {
                        this.$set(this.client, 'is_draft_pending', false);
                        for (let i = 0; i < this.clientTokenList.length; i += 1) {
                            const item = this.clientTokenList[i];
                            item.is_active = response.isPublished;
                            item.is_draft_pending = false;
                            this.clientTokenList.splice(i, 1, item);
                        }
                    }
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to publish client' });
                }
            } catch (err) {
                console.error('failed to publish client', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to publish client' });
            } finally {
                this.$store.commit('loading', { publishClient: false });
            }
        },
        async unpublishClient() {
            try {
                this.error = false;
                this.$store.commit('loading', { publishClient: true });
                const response = await this.$client.organization(this.$route.params.organizationId).client.unpublish({ id: this.$route.query.id });
                console.log(`publishClient: response ${JSON.stringify(response)}`);
                if (typeof response?.isPublished === 'boolean') {
                    this.$set(this.client, 'is_active', response.isPublished);
                    if (!response.isPublished) {
                        this.$set(this.client, 'is_draft_pending', true);
                    }
                    for (let i = 0; i < this.clientTokenList.length; i += 1) {
                        const item = this.clientTokenList[i];
                        item.is_active = false;
                        item.is_draft_pending = true;
                        this.clientTokenList.splice(i, 1, item);
                    }
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to unpublish client' });
                }
            } catch (err) {
                console.error('failed to unpublish client', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to unpublish client' });
            } finally {
                this.$store.commit('loading', { publishClient: false });
            }
        },
        */
        /*
        async createToken() {
            try {
                this.error = false;
                this.$store.commit('loading', { saveClientAttr: true });
                const response = await this.$client.organization(this.$route.params.organizationId).clientToken.create({
                    client_id: this.$route.query.id,
                });
                console.log(`saveClientAttr: response ${JSON.stringify(response)}`);
                if (response?.isCreated && response.item) {
                    const editedItem = { ...response.item, is_active: false, is_draft_pending: true };
                    this.clientTokenList.push(editedItem);
                    this.$set(this.client, 'is_draft_pending', true);
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create client token' });
                }
            } catch (err) {
                console.error('failed to edit client token', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create client token' });
            } finally {
                this.$store.commit('loading', { saveClientAttr: false });
            }
        },
        */
        async saveClientToken(item) {
            try {
                this.error = false;
                this.$store.commit('loading', { saveClientToken: true });
                const response = await this.$client.organization(this.$route.params.organizationId).clientToken.edit({ id: item.id }, item);
                console.log(`saveClientToken: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    const editedItem = { ...item, is_draft_pending: true };
                    const idx = this.clientTokenList.findIndex((storedItem) => storedItem.id === item.id);
                    if (idx > -1) {
                        this.clientTokenList.splice(idx, 1, editedItem);
                    } else {
                        this.clientTokenList.push(editedItem);
                    }
                    this.$set(this.client, 'is_draft_pending', true);
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to save client token' });
                }
            } catch (err) {
                console.error('failed to save client token', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to cresaveate client token' });
            } finally {
                this.$store.commit('loading', { saveClientToken: false });
            }
        },
        async createClientToken() {
            if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitFormTimestamp = Date.now();
            try {
                this.$store.commit('loading', { createClientToken: true });
                const item = {
                    client_id: this.$route.query.id,
                    label: this.newClientTokenLabel,
                };
                const response = await this.$client.organization(this.$route.params.organizationId).clientToken.create(item);
                if (response?.isCreated && response.id && response.token) {
                    this.createNewClientTokenDialog = false;
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    this.displayToken = response.token;
                    // this.clientTokenList.push(response.item);
                    this.loadClientTokenList();
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
                }
            } catch (err) {
                console.error('createClientToken failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
            } finally {
                this.$store.commit('loading', { createClientToken: false });
            }
        },
        async openEditClientTokenDialog(item) {
            this.editItemId = item.id;
            // this.editItemDomain = item.domain;
            this.editClientTokenLabel = item.label;
            // this.editItemValue = item.value;
            this.editClientTokenDialog = true;
        },
        async selectTokenForClipboard() {
            const input = this.$refs.displayTokenRef.$el.querySelector('textarea');
            input.focus();
            input.select();
            input.setSelectionRange(0, input.value.length);
        },
        async copyTokenToClipboard() {
            let isCopied = false;
            if (navigator?.clipboard) {
                try {
                    await navigator.clipboard.writeText(this.displayToken);
                    isCopied = true;
                } catch (err) {
                    console.error('failed to copy token to clipboard using Clipboard API', err);
                }
            }
            if (!navigator?.clipboard || !isCopied) {
                try {
                    this.selectTokenForClipboard();
                    document.execCommand('copy');
                    isCopied = true;
                } catch (err) {
                    console.error('failed to copy token to clipboard using document.execCommand', err);
                }
            }
            if (isCopied) {
                this.$bus.$emit('snackbar', { type: 'info', message: 'Copied token to clipboard' });
            } else {
                this.$bus.$emit('snackbar', { type: 'warning', headline: 'Cannot copy token to clipboard', message: 'Use mouse or keyboard commands instead' });
            }
        },
    },
    mounted() {
        this.loadOrganization();
        this.loadClient();
    },
};
</script>
